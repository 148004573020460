.transactions-container {
  font-family: 'Arial', sans-serif;
  background-color: #333;
  color: #fff;
  padding: 12px;
  overflow-x: auto; /* Permite el desplazamiento horizontal */
}

h1 {
  color: #fff;
  margin-bottom: 20px;
}

.filters {
  margin-bottom: 20px;
}

.filters input {
  padding: 8px;
  margin-right: 10px;
  border: none;
  border-radius: 4px;
}

table {
  width: 100%;
  border-collapse: collapse;
  background-color: #444;
  color: #fff;
}

th, td {
  padding: 10px;
  border: 1px solid #555;
}

th {
  background-color: #555;
}

tbody tr:hover {
  background-color: #555;
}

.save-button {
  background-color: #222;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  float: right;
  margin-top: 20px;
}

.save-button:hover {
  background-color: #444;
}
.large-button {
  padding: 10px 20px; /* Aumenta el tamaño del botón */
  font-size: 16px; /* Aumenta el tamaño del texto */
  background-color: #4CAF50; /* Cambia el color de fondo */
  color: white; /* Cambia el color del texto */
  border: none; /* Quita el borde */
  border-radius: 5px; /* Redondea las esquinas */
  cursor: pointer; /* Cambia el cursor al pasar sobre el botón */
}

.large-button:hover {
  background-color: #45a049; /* Cambia el color de fondo al pasar el mouse */
}

.select-container {
  position: relative;
  display: inline-block;
}

.select-container select {
  font-family: 'Arial', sans-serif;
  background-color: #333;
  color: #fff;
  padding: 8px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.select-container select:focus {
  outline: none;
}

.select-container::after {
  content: '\25BC';
  color: #fff;
  font-size: 12px;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  pointer-events: none;
}


@media (max-width: 768px) {
  th, td {
    display: block; /* Cambia a bloque para que las celdas se apilen */
    width: 100%; /* Asegura que ocupen todo el ancho */
  }

  tr {
    display: flex; /* Cambia a flexbox */
    flex-direction: column; /* Coloca las celdas en una columna */
    margin-bottom: 10px; /* Espaciado entre filas */
  }
}

@media (max-width: 768px) {
  th, td {
    font-size: 14px; /* Ajusta el tamaño de la fuente */
  }

  h1 {
    font-size: 18px; /* Ajusta el tamaño del encabezado */
  }
}

@media (max-width: 768px) {
  .save-button, .large-button {
    padding: 8px 16px; /* Ajusta el tamaño del botón */
    font-size: 14px; /* Ajusta el tamaño del texto */
  }
}

@media (max-width: 768px) {
  .filters {
    margin-bottom: 10px; /* Reduce el margen */
  }

  .save-button {
    margin-top: 10px; /* Ajusta el margen superior */
  }
}